<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	import facebookLogin from 'facebook-login-vuejs';
	import GoogleLogin from 'vue-google-login';
	export default {
		extends: BaseVue,
		components: {
			facebookLogin,
			GoogleLogin
		},
		data() {
			return {
				input: {},
				params: {
					clientId: '228499823754-glc0vf2m8l5gmhk4odsbgkunedciis4o.apps.googleusercontent.com'
				},
			}
		},
		computed: {
			mrValidation() {
				return this.$root.config.mrValidation.register
			}
		},
		async mounted() {
			await this.ready();
			setTimeout(() => { 
				SEMICOLON.header.init();
				SEMICOLON.widget.extras(); }, 300)
			this.$set(this.$root, 'page', this);
			this.$set(this.input, 'agreement', true)
		},
		methods: {
			onSubmit(e) {
				if (e && e.btnLoading()) return;
				Gen.apirest('/register', this.input, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) return swal(err.resp.message, "", "warning")
					this.input = {}
					this.$root.user = resp.user
					Gen.putCookie("fotk", resp.token)
					Gen.putStorage("fo_user", resp.user)
					swal(resp.message, "", "success")
					this.$router.push({
						name: "Login"
					})
				}, "POST")
			},
			onSubmitFb(id, name) {
				Gen.apirest('/register-facebook', { id: id, name: name, }, (err, resp) => {
					if (err) return swal(err.resp.message, "", "warning")
					Gen.putCookie("fotk", resp.token)
					this.$root.user = resp.user
					Gen.putStorage("fo_user", resp.user)
					swal(resp.message, "", "success")
					this.$router.push({
						name: "Dashboard"
					})
				}, 'POST')
			},
			onSubmitGoogle(id, name, email) {
				Gen.apirest('/register-google', { id: id, name: name, email: email, }, (err, resp) => {
					if (err) return swal(err.resp.message, "", "warning")
					Gen.putCookie("fotk", resp.token)
					this.$root.user = resp.user
					Gen.putStorage("fo_user", resp.user)
					swal(resp.message, "", "success")
					this.$router.push({
						name: "Dashboard"
					})
				}, 'POST')
			},
			getUserData() {
				FB.login((response) => {
					if (response.authResponse) {
						console.log('Welcome!  Fetching your information.... ');
						FB.api('/me', (response) => {
							// console.log('Good to see you, ' + response.name + '.');
							this.onSubmitFb(response.id, response.name)
						});
					} else {
						console.log('User cancelled login or did not fully authorize.');
					}
				});
			},
			onSuccess(googleUser) {
				let profile = googleUser.getBasicProfile();
				let id = profile.getId()
				let name = profile.getName()
				let email = profile.getEmail()
				// let id_token = googleUser.getAuthResponse().id_token;
				this.onSubmitGoogle(id, name, email)
			},
			onFailure() {

			},
		},
	};
</script>
<template>
	<section id="content" style="background-color: #000; overflow: visible">
		<div class="content-wrap pt-0 pb-0">
			<div id="section-about" class="section page-section bg_0f181b nobottommargin notopmargin clearfix">
				<div class="container clearfix">
					<div class="row  justify-content-center dark">
						<div class="col-md-6 col-lg-5 ">
							<div class="wrap_login">
								<h2>{{web.lbl_sign_up}}</h2>
								<h4>{{web.lbl_sign_up_below}}.</h4>
								<VForm @resp="onSubmit">
									<div class="info"></div>
									<div class="row">
										<div class="col-md-12 mb-2">
											<div class="form-group">
												<input type="text" v-model="input.fullname" v-bind="validation('fullname')" onkeydown="return fullNameKey(event)" name="fullname" class="frm_custom_sign" placeholder="Full Name">
												<LabelError name="fullname"></LabelError>
											</div>
										</div>
										<div class="col-md-12 mb-2">
											<div class="form-group">
												<input type="text" v-if="!isMobile" v-model="input.phone" v-bind="validation('phone')" onkeydown="return mobileKey(event)" name="phone" class="frm_custom_sign" placeholder="No. Handphone">
												<input type="tel" v-if="isMobile" v-model="input.phone" v-bind="validation('phone')" onkeydown="return mobileKey(event)" name="phone" class="frm_custom_sign" placeholder="No. Handphone">
												<LabelError name="phone"></LabelError>
											</div>
										</div>
										<div class="col-md-12 mb-2">
											<div class="form-group">
												<input type="email" v-if="!isMobile" v-model="input.email" v-bind="validation('email')" onkeydown="return emailKey(event)" name="email" class="frm_custom_sign" placeholder="Email Address">
												<input type="email" v-if="isMobile" v-model="input.email" v-bind="validation('email')" onkeydown="return emailKey(event)" name="email" class="frm_custom_sign" placeholder="Email Address">
												<LabelError name="email"></LabelError>
											</div>
										</div>
										<div class="col-md-12 mb-2">
											<div class="form-group">
												<input type="password" v-model="input.password" v-bind="validation('password')" name="password" id="password" class="frm_custom_sign" placeholder="Password">
												<LabelError name="password"></LabelError>
											</div>
										</div>

										<div class="col-md-12 mb-2">
											<div class="form-group">
												<input type="password" name="confirm_password" v-model="input.confirm_password" v-bind="validation('confirm_password')" class="frm_custom_sign" placeholder="Confirm Password">
												<LabelError name="confirm_password"></LabelError>
											</div>
										</div>
										<div class="col-md-12">
											<div class="divider divider-short nomargin divider-center">{{web.lbl_or}}
											</div>
										</div>
										<div class="col-md-12 text-center">
											<!-- <a href="" class="btn_gray_sosmed"><i class="icon-google1"></i></a> -->
											<GoogleLogin class="btn_gray_sosmed ml-0" :params="params"
												:onSuccess="onSuccess" :onFailure="onFailure">
												<i class="icon-google1"></i>
											</GoogleLogin>
											<facebook-login class="btn_gray_sosmed px-0" appId="739123363526240"
												@login="getUserData" loginLabel=""></facebook-login>
											<!-- <a href="" class="btn_gray_sosmed"><i class="icon-facebook2"></i></a> 1147877025559613 -->
										</div>
										<div class="col-md-12 mt-3">
											<div>
												<input id="checkbox-10" class="checkbox-style" v-model="input.agreement"
													name="checkbox-10" type="checkbox" checked>
												<label for="checkbox-10" class="checkbox-style-2-label"><span>{{web.lbl_sign_up_checklist}}</span>
												</label>
											</div>
										</div>
										<div class="col-md-12 mt-3 text-center">
											<p><button class="btn_act w_100 btn-loading">{{web.lbl_sign_up}} <i class="icon-line-arrow-right"></i></button></p>
										</div>
									</div>
								</VForm>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="clear"></div>
		</div>
	</section>
</template>